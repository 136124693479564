import React from "react";

import { NotFoundPage, getProperty } from "@btc/shared";

import { useMeta } from "hooks";

const Template = () => {
  const meta = useMeta();

  const title = getProperty(meta, "meta.error.title");
  const text = getProperty(meta, "meta.error.content");
  const button = getProperty(meta, "meta.back_home");
  const homePath = getProperty(meta, "meta.home.link.path");

  return <NotFoundPage title={title} text={text} button={button} homePath={homePath} />;
};

export default Template;
